// Agree to continue page

import "../css/a.css"

const AgreeTerms = () => {
    return (
        <div className="agree-terms">
            <div className="img-container">
                {/* <img src={berg} alt="glg" /> */}
                <small>Bloomberg</small>
            </div>
            <div className="a-text">
                <h3>Sign in to Bloomberg Accounts</h3>
                <div className="a-addr">
                    <span>c</span>
                    <span>custom@email.com</span>
                </div>
            </div>
            <div className="a-tos">
                <p>
                    By continuing, Google will share your name, email address, language preference, and profile picture with Bloomberg Accounts. See Bloomberg Account's <span>Privacy Policy</span> and <span>Terms of Service</span>.
                </p>
                <p>
                    You can manage Sign in with Google in your <span>Google Account</span>
                </p>
            </div>
            <div className="a-buttons">
                <button>Cancel</button>
                <button>Continue</button>
            </div>
        </div>
    )
}

export default AgreeTerms