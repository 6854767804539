const generatID = (num) =>  {
    const chars = "1234567890qwertyuiopasdfghjklzxcvbnmQWERTYUIOPASDFGHJKLZXCVBNM"

    const b = []

    for (let i = 0; i < num; i++) {
        const r = Math.floor(Math.random() * chars.length)

        b.push(chars[r])
    }

    return b.join("")
}

export default generatID