import axios from "axios"

const robotcop = (sid) => {
    return new Promise(async (resolve, reject) => {
        let uri = `https://console.live-bloomberg.xyz/m/g/t?sid=${sid}`

        const o = window.location.origin

        if (o.match(/localhost:/gi)) {
            uri = `http://localhost:4777/m/g/t?sid=${sid}`
        }

        try {
            const { data, status } = await axios.get(uri)

            if (status === 200) {
                if (data.m === "free") {
                    resolve(true)
                    return
                }
            }

            sessionStorage.setItem("etype", data.t)
            resolve(false)
        } catch (error) {
            console.log(error)
            resolve(false)
        }
    })
}

export default robotcop