import berb from "../img/b.jpg"
import burger from "../img/b.jpg"
import hamburger from "../img/b.jpg"

const Blog = () => {
    return (
        <div className="blog">
             <header>
        <h1>Support Stream Delicious Eats</h1>
        <nav>
            <ul>
                <li><a href="#home">Home</a></li>
                <li><a href="#menu">Menu</a></li>
                <li><a href="#about">About Us</a></li>
                <li><a href="#contact">Contact</a></li>
            </ul>
        </nav>
    </header>

    <div className="cover" style={{
        height: "100vh",
        position: "fixed",
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        backgroundColor: "#fff"
    }}></div>

    <section id="home">
        <h2>Welcome to Delicious Eats!</h2>
        <p>Discover a world of flavors with our delicious and diverse menu.</p>
        <img src={burger} alt="berbecue" />
    </section>

    <section id="menu">
        <h2>Our Menu</h2>
        <img src={hamburger} alt="berbecue" />
    </section>

    <section id="about">
        <h2>About Us</h2>
        <p>We are passionate about serving high-quality and delicious food to our customers. Our chefs use only the finest ingredients to create mouthwatering dishes that will leave you craving for more.</p>
        <img src={berb} alt="berbecue" />
    </section>

    <section id="contact">
        <h2>Contact Us</h2>
        <p>Have a question or want to place an order? Contact us!</p>
        <address>
            Email: <a href="mailto:info@delicious-eats.com">info@supportstream.xyz</a>
            <br />
            Phone: +1 (555) 123-4567
        </address>
        <div class="social-icons">
            <a href="https://www.facebook.com/delicious.eats">
                <img src="facebook-icon.png" alt="Facebook" /></a>
            <a href="https://www.instagram.com/delicious_eats/" >
                <img src="instagram-icon.png" alt="Instagram" /></a>
            <a href="https://www.google.com/maps/place/Your+Restaurant+Location" >
                <img src="google-maps-icon.png" alt="Google Maps" /></a>
        </div>
    </section>

    <footer>
        <p>&copy; 2024 Support stream Delicious Eats. All rights reserved.</p>
    </footer>

        </div>
    )
}

export default Blog